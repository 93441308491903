export const VERSION = 'quito2'

//SUBSCRIPTION PLANS
export const DEFAULT_PRODUCT_VERSION = 'v5'

export const DEFAULT_SYSTEM_SUBSCRIPTION = `proven-system-8-weeks-${DEFAULT_PRODUCT_VERSION}`
export const DEFAULT_SYSTEM_SUBSCRIPTION_4_WEEKS = `proven-system-4-weeks-${DEFAULT_PRODUCT_VERSION}`
export const DEFAULT_SYSTEM_SUBSCRIPTION_6_WEEKS = `proven-system-6-weeks-${DEFAULT_PRODUCT_VERSION}`
export const DEFAULT_SYSTEM_SUBSCRIPTION_8_WEEKS = `proven-system-8-weeks-${DEFAULT_PRODUCT_VERSION}`
export const DEFAULT_SYSTEM_SUBSCRIPTION_10_WEEKS = `proven-system-10-weeks-${DEFAULT_PRODUCT_VERSION}`

export const DEFAULT_CLEANSER_SUBSCRIPTION_4_WEEKS = `proven-cleanser-4-weeks-${DEFAULT_PRODUCT_VERSION}`
export const DEFAULT_CLEANSER_SUBSCRIPTION_6_WEEKS = `proven-cleanser-6-weeks-${DEFAULT_PRODUCT_VERSION}`
export const DEFAULT_CLEANSER_SUBSCRIPTION_8_WEEKS = `proven-cleanser-8-weeks-${DEFAULT_PRODUCT_VERSION}`
export const DEFAULT_CLEANSER_SUBSCRIPTION_10_WEEKS = `proven-cleanser-10-weeks-${DEFAULT_PRODUCT_VERSION}`

export const DEFAULT_SPF_SUBSCRIPTION_4_WEEKS = `proven-spf-4-weeks-${DEFAULT_PRODUCT_VERSION}`
export const DEFAULT_SPF_SUBSCRIPTION_6_WEEKS = `proven-spf-6-weeks-${DEFAULT_PRODUCT_VERSION}`
export const DEFAULT_SPF_SUBSCRIPTION_8_WEEKS = `proven-spf-8-weeks-${DEFAULT_PRODUCT_VERSION}`
export const DEFAULT_SPF_SUBSCRIPTION_10_WEEKS = `proven-spf-10-weeks-${DEFAULT_PRODUCT_VERSION}`

export const DEFAULT_NIGHTCREAM_SUBSCRIPTION_4_WEEKS = `proven-night-cream-4-weeks-${DEFAULT_PRODUCT_VERSION}`
export const DEFAULT_NIGHTCREAM_SUBSCRIPTION_6_WEEKS = `proven-night-cream-6-weeks-${DEFAULT_PRODUCT_VERSION}`
export const DEFAULT_NIGHTCREAM_SUBSCRIPTION_8_WEEKS = `proven-night-cream-8-weeks-${DEFAULT_PRODUCT_VERSION}`
export const DEFAULT_NIGHTCREAM_SUBSCRIPTION_10_WEEKS = `proven-night-cream-10-weeks-${DEFAULT_PRODUCT_VERSION}`

//ONETIME PLANS
export const DEFAULT_SYSTEM_ONETIME = `proven-system-${DEFAULT_PRODUCT_VERSION}`
export const DEFAULT_CLEANSER_ONETIME = `proven-cleanser-${DEFAULT_PRODUCT_VERSION}`
export const DEFAULT_SPF_ONETIME = `proven-spf-${DEFAULT_PRODUCT_VERSION}`
export const DEFAULT_NIGHTCREAM_ONETIME = `proven-night-cream-${DEFAULT_PRODUCT_VERSION}`

//PRICING & DISCOUNTS
export const DEFAULT_SYSTEM_SUBSCRIPTION_PRICE = 129.99
export const DEFAULT_SYSTEM_ONETIME_PRICE = 169.99
export const DEFAULT_SAVINGS_PERCENT = 32
export const DEFAULT_SAVINGS_AMOUNT = 30
export const UK_DEFAULT_SAVINGS_AMOUNT = 25
export const SHOP_SAVINGS_AMOUNT = 50
export const DEFAULT_EYE_CREAM_DUO_SUBSCRIPTION_PRICE = 129.99
